import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet"
import { useForm } from "react-hook-form";
import axios from "axios";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField, InputAdornment, IconButton, Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AppleAuth from "../../components/auth/AppleAuth";
import bgImage from "../../assets/images/bottom-bg1.svg"
import kinoImg from "./kinoImage2.png";

import "./SignIn.css"

const kinoTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(3, 40, 54)",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    color: "#032836",
    fontSize: "2.5rem",
  },
  mainDescr: {
    textAlign: "center",
    color: "#032836",
    fontSize: "0.9rem"
  },
  orangeTitle: {
    color: "#FF824D", 
  },
  socialNetworkTitle: {
    color: "#FF824D", 
    fontWeight: "600",
    margin: "0.6rem 0"
  },
  termsDescriptions: {
    width: "100%",
    marginTop: "1rem",
    color: "gray",
    fontWeight: "600",
    fontSize: "0.8rem",
    textAlign: "center"
  },
  takeMeInTitle: {
    color:" #FF824D",
    fontSize: "3.5rem",
    textAlign: "center",
    marginBottom: "8rem",
    marginTop: "3.5rem"
  },
  takeMeInDescription: {
    color: "#B8C1C5",
    fontSize: "2rem",
    textAlign: "center",
    marginBottom: "4rem"
  },
  signInButton: {
    backgroundColor: "#FF824D",
    width: "100%",
    maxWidth: "200px",
    color: "#ffff",
    fontSize: "1rem !important",
    fontWeight: "900 !important",
    marginTop: "3rem"
  }, 
  loginRoot: {
    border: "3px solid lightgray",
    borderRadius: "18px",
    padding:"3rem",
    margin: "0rem auto",
  maxWidth: "600px",
  },
  login: {
    padding: "0 5rem",
    maxWidth: "850px",
    margin: "0 auto",
  },
  dialog: {
    backgroundColor: "#f8f8f8",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },
  container_forgot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5\rem",
  },
  textField: {
    marginBottom: "0.8rem"
  },
  textFieldModified: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  textFieldSm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  title: {
    fontSize: "2rem",
    textAlign: "center",
    color: "var(--darkColor)",
    lineHeight: "1.2",
    marginBottom: "2rem",
    fontWeight: 600
  },
  titleSm: {
    fontSize: "1rem",
    textAlign: "center",
    color: "var(--darkColor)",
    fontWeight: "500",
    width: "85%",
    margin: "auto",
  },
  terms: {
    marginLeft: "-1.5rem",
    alignSelf: "center",
    cursor: "pointer",
  },
  forgot: {
    cursor: "pointer",
    maxWidth: "72%",
  },
  
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "var(--darkColor)",
    color: "var(--grey)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "var(--darkColor)",
      boxShadow: "none",
    },
    marginTop: ".5rem",
    maxWidth: "200px",

  },
  link: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
    color: "#032836",
  },
  formError: {
    fontSize: "14px",
    color: "red",
    "&::before": {
      content: "⚠ ",
    },
  },
  sign: {
    color: "var(--darkColor)",
    fontSize: "1rem",
    fontWeight: 400,
  },
  checkbox: {
    display: "flex",
    height: "30px",
  },
  start: {
    backgroundColor: "var(--darkColor)",
    border: "1px solid var(--grey)",
    color: "var(--grey)",
    minWidth: "20ch",
  },
  mainError: {
    textAlign: "center",
    color: "red",
    fontWeight: "400",
  },
  alternateAuth: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    gap: "2rem",
  },
  authSeparator: {
    textAlign: "center",
    fontSize: "16px",
    margin: "1.5rem 8px",
    borderBottom: "1px solid #808080",
    lineHeight: "0rem",
  },
  authSeparatorText: {
    backgroundColor: "#fff",
    padding: "0 1rem",
  },
  form: {
    maxWidth: "450px",
    margin: "0 auto"
  },
  rememberMeBox: {
    display: "flex",
    alignItems: "center"
  },
  rememberMeCheckBox: {
    paddingLeft: "0"
  }
}));

const SignIn = ({ ...rest }) => {
  const [remember_me, setRemember_me] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorForm, setErrorForm] = useState("");
  const [errorFormNotVerified, setErrorFormNotVerified] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signInDisable, setSignInDisable] = useState(false);
  const { register, errors, handleSubmit } = useForm({});
  let timer = null;
  const { email, password } = formData;

  const timeZone = moment().utcOffset();
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  const classes = useStyles();

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleLoginClose = () => {
    rest.setModalOpen(false);
    setShowPassword(false);
  };

  const handleRegisterOpen = () => {
    rest.setRegisterOpen(true);
  };

  const switchLoginRegister = () => {
    handleLoginClose();
    handleRegisterOpen();
  };

  const handleChangeCheck = () => {
    setRemember_me(!remember_me);
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorForm("");
  };

  const onSubmit = async (e) => {
    // e.preventDefault();
    setSignInDisable(true);
    setTimeout(() => {
      setSignInDisable(false);
    }, 10000);

    axios
      .post(
        "/login",
        {
          email: email,
          password: password,
          _token: token,
          utcOffset: timeZone,
        },
        config
      )
      .then(
        (response) => {
          if (response.data.error === undefined) {
            //success
            clearTimeout(timer);
            window.location.href="/profile";
            // if (
            //   rest.redirectAnchor === undefined ||
            //   rest.redirectAnchor == null
            // ) {
            //   window.location.replace(response.data.redirect);
            // } else {
            //   handleLoginClose();
            //   rest.setRedirectAnchor("");
            //   window.location.replace(`/${rest.redirectAnchor}`);
            // }
          } else {
            //something went wrong
            clearTimeout(timer);
            setSignInDisable(false);
            if (response.data.error === "Email verification is required") {
              setErrorForm("");
              setErrorFormNotVerified(`Email verification is required,`);
            } else {
              setErrorFormNotVerified("");
              setErrorForm(response.data.error);
            }
          }
        },
        (error) => {
          console.log("error data: ", error.response.data);
          clearTimeout(timer);
          setSignInDisable(false);
        }
      );
  };

  const handleVerificationResend = () => {
    axios
      .post(
        "/verification-resend",
        {
          email: email,
        },
        config
      )
      .then(
        (response) => {
          if (response.data.message === "Verification email has been sent") {
            setErrorFormNotVerified("");
            setErrorForm("");
            handleLoginClose();
            rest.setVerifyEmail(email);
            rest.setVerifyModalOpen(true);
          }
        },
        (error) => {
          console.log(error.response.data);
          setErrorFormNotVerified("");
        }
      );
  };

  const forgotPassword = () => {
    window.location.href = "/password/reset";
    handleLoginClose();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    rest.modalOpen === false && setErrorForm("") && setErrorFormNotVerified("");
  }, [rest.modalOpen]);

  const props = {
    errorForm,
    setErrorForm,
    handleVerificationResend,
    setFormData,
  };
  return (
    <div className="signin-section">
      <Helmet>
        <title>Kino | Sign In</title>
      </Helmet>
      <div className="singin-content">
        <Grid container className="signin-container">
          {/* <img src={img} /> */}
          <Grid md={6} sm={12} className="form-conatiner">
            <div className="kino-logo-container-small">
              <img src={kinoImg} />
            </div>
            <div className="sing-up-content">
              <h1 className={classes.mainTitle} id = 'mainTitle'>Welcome back</h1>
              <p className={classes.mainDescr} id='mainDescr'>Sign in using social networks</p>
              <div className={classes.alternateAuth + " sign-in-alternate-auth"}>
                {/* Sign up with google */}
                <div>
                  <div
                    className="auth-provider-sign-in-page google-login-sign-up-page"
                    onClick={() => {
                      if(window.gtag_signup_conversion){
                        window.gtag_signup_conversion('google')
                      }
                      window.location.replace("/signin/google");
                    }}
                  >
                    <svg
                      aria-hidden="trauth-psingue"
                      className="svg-icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g>
                        <path
                          d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"
                          fill="#EA4335"
                        ></path>
                      </g>
                    </svg>
                    {/* Sign up with Google */}
                  </div>
                </div>
                {/* Sign up with facebook */}
                <div>
                  <div
                    className="auth-provider-sign-in-page facebook-login-sign-in-page"
                    onClick={() => {
                      if(window.gtag_signup_conversion){
                        window.gtag_signup_conversion('facebook')
                      }
                      window.location.replace("/signin/facebook");
                    }}
                    style={{borderColor: "#1877F2"}}
                  >
                    <svg
                      width="534"
                      height="531"
                      viewBox="0 0 534 531"
                      fill="#1877F2"
                      
                    >
                      <path
                        d="M533.667 267C533.667 119.724 414.276 0.333344 267 0.333344C119.724 0.333344 0.333344 119.724 0.333344 267C0.333344 400.101 97.8494 510.422 225.333 530.427V344.083H157.625V267H225.333V208.25C225.333 141.417 265.145 104.5 326.057 104.5C355.233 104.5 385.75 109.708 385.75 109.708V175.333H352.124C318.997 175.333 308.667 195.889 308.667 216.978V267H382.625L370.802 344.083H308.667V530.427C436.151 510.422 533.667 400.101 533.667 267"
                        fill="#1877F2"
                      />
                    </svg>
                    {/* Sign up with Facebook */}
                  </div>
                </div>
                  <AppleAuth
                    {...rest}
                    {...props}
                    type={"signup"}
                    // buttonName="Sign up with Apple"
                    method={"signup"}
                    className={"sign-in"}
                  />
              </div>
              <p className={classes.socialNetworkTitle} id='socialNetworkTitle'>or use your email for sign in</p>
              <form className="signin-form">
                <TextField
                  margin="dense"
                  id="email"
                  name="email"
                  label={errors.email ? errors.email.message : "Email Address"}
                  error={errors.email && errors.email.message}
                  type="email"
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  onChange={(e) => handleChange(e)}
                  inputRef={register({
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {/* {errors.email && (
                  <p className={classes.formError}>
                    {"⚠ "}
                    {errors.email.message}
                  </p>
                )} */}
                <TextField
                  margin="dense"
                  id="password"
                  name="password"
                  label={errors.password ? errors.password.message : "Password"}
                  error={errors.password && errors.password.message}
                  style={{width: "100%"}}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  inputRef={register({
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                      message:
                        "Password must include a combination of uppercase, lowercase letters and numbers.",
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
{/* 
                {errors.password && (
                  <p className={classes.formError}>
                    {"⚠ "}
                    {errors.password.message}
                  </p>
                )}
                {errorForm ? (
                  <p className={classes.formError}>
                    {"⚠ "}
                    {errorForm}
                  </p>
                ) : (
                  ""
                )} */}

              <div className={classes.container_forgot}>
                <div className="flex row-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={remember_me}
                        onClick={(e) => handleChangeCheck(e)}
                        name="remember-me"
                        id="remember-me"
                        color="primary"
                        className={classes.checkbox}
                        value={remember_me}
                      />
                    }
                  />
                  <label className={classes.terms} htmlFor="remember-me">
                    Remember me
                  </label>
                </div>
                <p className={classes.forgot} onClick={forgotPassword}>
                  Forgot password?
                </p>
              </div>
                <div className={classes.container}>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    fullWidth
                    className={classes.button}
                    // disabled={!recaptchaValue}
                    type="submit"
                  >
                    {<span class="spanButtonText">TAKE ME IN</span>}
                    { (
                      <div>
                        <CircularProgress
                          style={{
                            height: "17px",
                            width: "17px",
                            marginRight: "5px",
                            color: "#032836",
                            verticalAlign: "middle",
                          }}
                        />
                      </div>
                    )}
                  </Button>
                </div>
              <p className="not-a-member">Not a member? <a href="/signup" className="sign-up-text">SIGN UP</a></p>
              </form>
   
             
            </div>
          </Grid>
          <Grid md={6} sm = {0} direction="column" className="take-me-in-container">
            <h1 className={classes.takeMeInTitle}> New Here?</h1>
            <h3 className={classes.takeMeInDescription} id="take-me-in-desc">Sign up and embark on <br></br> great new opportunities!</h3>
            <a  href="/signup"
                style={{
                  textDecoration: "none",
                  color: "#ffff",
                  cursor: "pointer",
                  minWidth: "200px"
                }}>
            <Button variant="contained" type="submit" id="takeMeIn" className={classes.signInButton}>
              CREATE ACCOUNT
            </Button>
            </a>
          </Grid>
        </Grid>
        <p className={classes.termsDescriptions}>
                By signing into your Kino account, you accept Kino's{" "}
                <a href="kino.live" className={classes.orangeTitle}>Terms & Conditions</a> and{" "}
                <a href="kino.live" className={classes.orangeTitle}>Privacy Policy</a>. By proceeding I confirm that
                I am 18 years of age or older.
        </p>
      </div>
    
    </div>
  );
};

export default SignIn;


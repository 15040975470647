import React, { Fragment, useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { BreakpointProvider } from "react-socks";
import Navbar from "./components/Navbar/Navbar";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

// New Kino
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Privacy/Terms";
import Error404 from "../src/Views/Errors/Error404";
import Partnership from "../src/Views/Partnership/Partnership"
import Community from "../src/components/Community/Community"
import "./App.css";
// import Faq from "./Views/NewKino/Faq/Faq";
import SignIn from "./Views/SignIn/SignIn";
import SignUp from "./Views/SignUp/SignUp"; 
import VerifyEmail from "./components/Modals/VerifyEmail";

function App() {
  const [user, setUser] = useState({});
  const [isAuth, setIsAuth] = useState("");
  const [disableStartKino, setDisableStartKino] = useState(false);
  const [userCookie, setUserCookie] = useState({});
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [nameRequired, setNameRequired] = useState(false);
  const [tokenID, setTokenID] = useState("token id");
  const [showEmailField, setShowEmailField] = useState(false);
  const [platform, setPlatform] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [redirectAnchor, setRedirectAnchor] = useState("");
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState("");
  const [demoOpen, setDemoOpen] = useState(false);
  const [index, setIndex] = useState(1);
  const [openShowroomModal, setOpenShowroomModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const Homepage = React.lazy(() => import("./components/Homepage/Homepage"));
  const Footer = React.lazy(() => import("./Views/Footer/Footer"));

  const loadUser = () => {
    // setIsAuth(parseInt(document.getElementById('userAuth').value,0));
    setIsAuth(0); //Testing in localhost
  };

  useEffect(() => {
    loadUser();
    axios
      .get(`/user/data`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/user/cookie`)
      .then((response) => {
        setUserCookie(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    AOS.init({
      duration: 500,
    });
  }, []);

  const props = {
    isAuth,
    setIsAuth,
    user,
    userCookie,
    disableStartKino,
    setDisableStartKino,
    openEmailModal,
    setOpenEmailModal,
    nameRequired,
    setNameRequired,
    tokenID,
    setTokenID,
    modalOpen,
    setModalOpen,
    registerOpen,
    setRegisterOpen,
    platform,
    setPlatform,
    redirectAnchor,
    setRedirectAnchor,
    verifyEmail,
    setVerifyEmail,
    verifyModalOpen,
    setVerifyModalOpen,
    showEmailField,
    setShowEmailField,
    demoOpen,
    setDemoOpen,
    index,
    setIndex,
    openShowroomModal,
    setOpenShowroomModal,
    setMenuOpen,
    menuOpen,
  };

  const path = window.location.pathname;

  return (
    <div className="app">
      <div className="page-content-wrap" /*data-aos="fade-right"*/>
        <Suspense fallback={<div></div>}>
          <Router>
            <Fragment>
              <Navbar {...props} />
              <VerifyEmail {...props} />
              <BreakpointProvider>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Homepage {...props} />}
                  />
                  <Route exact path="/partnerships" render={() => <Partnership />}/>
                  <Route exact path="/community" render={() => <Community />}/>
                  <Route exact path="/privacy" render={() => <Privacy />}/>
                  <Route exact path="/terms" render={() => <Terms/>} />
                  <Route
                    exact
                    path="/signin"
                    render={() => <SignIn {...props} />}
                  />
                  <Route
                    exact
                    path="/signup"
                    render={() => <SignUp {...props} />}
                  />
                  <Route component={Error404} />
                </Switch>
              </BreakpointProvider>
              <Footer {...props} />
            </Fragment>
          </Router>
        </Suspense>
      </div>
    </div>
  );
}

export default App;

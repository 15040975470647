import React from "react";
import { v4 as uuidv4 } from "uuid";
import AppleIcon from "@material-ui/icons/Apple";
import { useScript, appleAuthHelpers } from "react-apple-signin-auth";
import doAuthApple from "./doAuthApple";

const AppleAuth = ({ ...rest }) => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const handleSubmit = async () => {
    const response = await appleAuthHelpers.signIn({
      authOptions: {
        clientId: "io.kinetx.kino-service-id",
        scope: "email name",
        redirectURI: `https://${window.location.hostname}/signin/apple/callback`,
        state: uuidv4(),
        nonce: uuidv4(),
        usePopup: true,
      },
      onError: (error) => console.error(error),
    });

    if (response) {
      // console.log("apple response",response)
      const {firstName,lastName}=response.user ? response.user.name : {firstName:null,lastName:null};
      if (rest.method === "signup") {
        doAuthApple({ idToken: response.authorization.id_token,first_name:firstName,last_name:lastName}, rest.method)
          .then((res) => {
            console.log(res);
            if (res.data.error) {
              console.log(res.data.error);
              if (
                res.data.message ===
                "There is already an account associated with this apple id."
              ) {
                rest.setGeneralMsg(res.data.message);
                return;
              }else if(res.data.message){
                rest.setGeneralMsg(res.data.message);
                return;
              }
            } else if(res.data.success){
              if(window.gtag_signup_conversion){
                window.gtag_signup_conversion()
              }
              window.location.href="/profile/dashboard";
              return
            }else {
              rest.setGeneralMsg("");
              rest.setPlatform("signin/apple");
              if (!response.user || response.user === undefined) {
                rest.setNameRequired(true);
              }else{
                if(window.gtag_signup_conversion){
                  window.gtag_signup_conversion()
                }
              }
              rest.setTokenID(response.authorization.id_token);
              rest.setOpenEmailModal(true);
              rest.setShowEmailField(true);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
            rest.setErrorForm("Something went wrong");
            return;
          });
      } else {
        doAuthApple({ idToken: response.authorization.id_token, first_name:firstName,last_name:lastName}, rest.method)
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              window.location.href = "/";
            } else if (
              res.data.error &&
              res.data.redirect === "/email-unverified"
            ) {
              rest.setFormData({ email: res.data.email, password: "" });
              rest.handleVerificationResend();
            } else {
              rest.setErrorForm(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            rest.setErrorForm("Something went wrong");
          });
      }
    } else {
      console.error("Error performing apple signin.");
    }
  };

  return (
    <div
      className={
        rest.type != "signup"
          ? "auth-provider apple-login"
          : `auth-provider-${rest.className}-page apple-login-sign-up-page`
      }
      style={{borderColor: "#878787"}}
      onClick={handleSubmit}
    >
      <AppleIcon />
      {rest.buttonName}
    </div>
  );
};

export default AppleAuth;
